import { AuthService } from '../services';
import { authConstants } from '../constants';
import { alertActions } from './'

const decodeHtml = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.documentElement.textContent;
};

const authService = new AuthService()

function login(username, password, client) {
  return dispatch => {
    authService.login(username, password, client)
      .then(response => {
        const {
          access_token,
          clientId,
          clientKey,
          userId,
        } = response.data;

        sessionStorage.setItem('access_token', access_token)
        sessionStorage.setItem('userId', userId)
        sessionStorage.setItem('clientId', clientId)
        sessionStorage.setItem('clientKey', clientKey)
        localStorage.setItem('clientKey', clientKey)

        window.location.reload(true)
      }).catch(error => {

        dispatch({
          type: authConstants.LOGIN_FAILURE
        })
        if (error.response) {
          // const message = decodeHtml(error.response.data.error)
          // console.log(message);

          dispatch(alertActions.error(error.response.data.error))
          return
        }

        if (error.message) {
          dispatch(alertActions.error(error.message))
          return
        }

        dispatch(alertActions.error("Error en el servidor"))

      })
  }
}

function signUp(user) {
  return dispatch => {
    authService.signUp(user)
      .then(() => {
        dispatch({
          type: authConstants.SIGNUP_SUCCESS
        })
        dispatch(alertActions.success('Activación exitosa'))
      })
      .catch(error => {
        dispatch({
          type: authConstants.SIGNUP_FAILURE
        })
        dispatch(alertActions.error(error.response.data))
      })
  }
}

function activeSession(keep_active_session) {
  return dispatch => {
    dispatch({ type: authConstants.ACTIVE_SESSION, payload: { keep_active_session } })
  }
}

function logout() {
  return dispatch => {
    authService.signout()
    sessionStorage.clear()
    dispatch({
      type: authConstants.LOGOUT
    })
  }
}

function sendEmailPassword(email, clientId) {
  return dispatch => {
    authService.sendEmailPassword(email, clientId)
      .then(response => {
        dispatch({
          type: authConstants.SENT_EMAIL_PAWSSWORD
        })
        dispatch(alertActions.success('Email enviado correctamente. Revise su bandeja con las instrucciones para restablecer su contraseña.'))
      })
      .catch(error => {
        dispatch({
          type: authConstants.SEND_EMAIL_PASSWORD_FAILURE
        })
        dispatch(alertActions.error(error.response.data))
      })
  }
}

function restorePassword(uuid) {
  return dispatch => {
    authService.restorePassword(uuid)
      .then(response => {
        dispatch({
          type: authConstants.RESTORE_PASSWORD_SUCCESS
        })
        dispatch(alertActions.success('Constraseña restablecida correctamente.'))
      })
      .catch(error => {
        dispatch(alertActions.error(error.response.data))
      })
  }
}

function refreshToken() {
  return async (dispatch) => {
    await authService.refreshToken(sessionStorage.getItem('refresh_token'))
      .then((response) => {
        dispatch({
          type: authConstants.REFRESH_TOKEN_SUCESS
        })
      })
      .catch(error => {
        dispatch({ type: authConstants.REFRESH_TOKEN_FAILURE })
      })
  }
}

const userLoggedOut = () => ({ type: authConstants.USER_LOGGED_OUT })

const setUserLoggedIn = () => ({ type: authConstants.LOGIN_SUCCESS })

export const authActions = {
  login,
  logout,
  signUp,
  activeSession,
  sendEmailPassword,
  refreshToken,
  restorePassword,
  userLoggedOut,
  setUserLoggedIn
}