import crypto from 'crypto';

export const getHash = (file) => new Promise((resolve, reject) => {
  const hash = crypto.createHash('sha256');
  const reader = new FileReader();
  reader.readAsArrayBuffer(file)
  reader.onerror = () => reject("error guardando")
  reader.onload = (ev) => {
    const result = ev.target.result;
    hash.update(result)
    resolve({ hash: hash.digest('hex'), buffer: result })
  }

})