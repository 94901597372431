import axios from 'axios';
import config from './config';

export const axiosRequest = axios.create({
  baseURL: `${config.gateway}/rest`
});

export const securityInstance = axios.create({
  baseURL: `${config.gateway}/api/security`
  // baseURL: `http://localhost:8070/api/security`
});

export const authHeader = () => ({ Authorization: `Bearer ${sessionStorage.access_token}` })

export const axiosBusinessRequest = axios.create({
  baseURL: `${config.gateway}/rest`,
  headers: authHeader()
});

export const axiosCalendarRequest = axios.create({
  baseURL: `${config.gateway}/rest`,
  headers: authHeader()
});

export const axiosRequestBlockChain = axios.create({
  baseURL: `${config.vault}/api/document`,
  // baseURL: 'http://localhost:8072/api/document',
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

export const vaultInstance = axios.create({
  baseURL: `${config.vault}/api/vaults`
  // baseURL: 'http://localhost:8072/api/vaults'
})

export const azureInstance = axios.create({
  baseURL: `${config.vault}/api/storage/azure`
  // baseURL: 'http://localhost:8072/api/storage/azure'
})

export const businessMgmtInstance = axios.create({
  baseURL: `${config.gateway}/api/business-management`
  // baseURL: 'http://localhost:8077/api/business-management'
})

export const axiosSignatureRequest = axios.create({
  baseURL: `${config.gateway}/api/signature`,
  // baseURL: `http://localhost:8075/api/signature`,
  headers: authHeader()
});

export const axiosTemplateRequest = axios.create({
  baseURL: `${config.gateway}/rest`,
  headers: authHeader()
});

export const axiosCollaboratorRequest = axios.create({
  baseURL: `${config.gateway}/rest`,
  headers: authHeader()
});

export const axiosCollaboratorUsersRequest = axios.create({
  baseURL: `${config.gateway}/rest`,
  headers: authHeader()
});

export const axiosGestorCapitalRequest = axios.create({
  baseURL: `${config.gateway}`,
  headers: authHeader()
});

