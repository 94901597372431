import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { reduxForm, Field } from 'redux-form';
import RenderTextField from '../../core/redux-form-fields/render-text-field';
import { isMobile } from 'axeleratum-sgc-frontend-library';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';
import logoAxeleratum from '../../assets/img/logo_axeleratum.png';

class RestorePassword extends Component {

  render() {
    const { handleSubmit, submitActions, onReturnSingIn, alert } = this.props;

    return (
      <form
        className={`${isMobile ? 'p-5' : ''} animate__animated animate__backInUp`}
        onSubmit={handleSubmit(submitActions)}
      >
        <Grid container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={1}>
            <IconButton
              className="primary-text"
              color="primary"
              edge="start"
              onClick={() => onReturnSingIn()}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>

          <Grid item xs={11}>
            <h3 className="primary-text">Restablecer contraseña</h3>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p>Ingresa tu correo para restablecer tu contraseña</p>
          </Grid>

          <Grid item xs={12}>
            <Field
              id="email"
              name="email"
              required={true}
              placeholder="Correo"
              label="Correo"
              component={RenderTextField}
            />
          </Grid>

          {
            alert.message &&
            <Grid item md={12} xs={12}>
              <Alert variant="filled" severity={`${alert.type}`}>
                {alert.message}
              </Alert>
            </Grid>
          }

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth={true}
              size="large">
              Enviar
            </Button>
          </Grid>


        </Grid>
      </form>
    );
  }

}

export const validate = (values) => {
  const errors = {
    email: ''
  }

  if (!values.email) {
    errors.email = 'El correo electrónico es requerido';
  } else {
    const emailReg = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (!emailReg.test(values.email) && values.email !== "Interno") {
      errors.email = "El correo electrónico es inválido"
    }
  }

  return errors;
}

export default reduxForm({
  form: 'RestorePassword',
  validate
})(RestorePassword);
