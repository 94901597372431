import React, { Component } from 'react';
import { Button, Grid, Checkbox, Link } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import RenderTextField from '../../core/redux-form-fields/render-text-field';
import Visibility from '@material-ui/icons/Visibility';
import Alert from '@material-ui/lab/Alert';
import { getCurrentUser } from '../../core/helpers';
import { AuthService } from '../../core/services';
import { withRouter } from 'react-router-dom';
import config from '../../configs/config';

class SignIn extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hasSSO: false,
    };
  }

  authService = new AuthService();

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location?.search);

    const access_token = searchParams.get('token')
    if (access_token) {
      sessionStorage.setItem('access_token', access_token)
      const { userId, client_id, clientKey } = getCurrentUser()
      sessionStorage.setItem('userId', userId)
      sessionStorage.setItem('clientId', client_id)
      sessionStorage.setItem('clientKey', clientKey)
      localStorage.setItem('clientKey', clientKey)
      this.props.userLoggedIn()
      this.props.history.replace("/dashboard");
    } else {
      const path = this.props.match?.params
      const clientKey = path?.clientKey
      if (clientKey) {
        this.authService.verifySSO(clientKey)
          .then(result => {
            this.setState({ hasSSO: result.data })
          })
          .catch(error => {
            console.log(error.response.data);
          })
      }
    }
  }


  render() {
    const {
      onCreateAccount,
      onRecoverPassword,
      handleSubmit,
      submitActions,
      alert,
      handleSession
    } = this.props;

    const handleClickSSO = () => {
      window.location.href = `${config.gateway}/auth/signin`
    }

    return (
      <form onSubmit={handleSubmit(submitActions)}>
        <Grid
          className={`animate__animated animate__backInUp`}
          container
          spacing={1}
          style={{ padding: '10px' }}
        >
          <Grid item xs={12} >
            <h3 style={{ marginBottom: '0px' }}>Ingresar</h3>
          </Grid>

          <Grid item md={12} xs={12}>
            <Field
              label="Correo"
              name="email"
              required={true}
              component={RenderTextField}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Field
              label="Contraseña"
              name="password"
              type="password"
              required
              passwordAction
              icon={
                <Visibility />
              }
              component={RenderTextField}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ display: "none" }}>
            <Checkbox color="primary" onChange={(ev) => handleSession(ev)} />
            <small className="validated-text">Mantener mi sesión abierta</small>
          </Grid>

          {
            alert.message &&
            <Grid item md={12} xs={12}>
              <Alert variant="filled" severity={`${alert.type}`}>
                {alert.message}
              </Alert>
            </Grid>
          }

          <Grid item md={12} xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              type="submit"
            >
              Ingresar
            </Button >
          </Grid>

          {
            this.state.hasSSO ? (
              <Grid item md={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  onClick={handleClickSSO}
                >
                  Inicio de sesión único (SSO)
                </Button >
              </Grid>
            ) : <></>
          }

          <Grid item md={12} xs={12}>
            <small className="validated-text text-center">
              ¿Olvidaste tu contraseña?&nbsp;
              <Link
                className="pointer"
                onClick={() => onRecoverPassword()}
                underline='always'
              >
                Reestablécela aquí.
              </Link>
            </small>
          </Grid>

          <Grid item className={'animate__animated animate__backInUp mt-5 pt-3 mb-3'} xs={12}>

          </Grid>
        </Grid>
      </form>
    );
  }

}

const validate = (values) => {
  const errors = {
    email: '',
    password: ''
  }

  if (!values.email) {
    errors.email = 'El correo electrónico es requerido';
  } else {
    const emailReg = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    if (!emailReg.test(values.email)) {
      errors.email = "Email inválido"
    }
  }

  if (!values.password) {
    errors.password = 'La contraseña es requerida';
  }

  if (values.email) {
    if (values.email.length > 50) {
      errors.email = 'El correo electrónico no puede ser mayor a 50 caracteres';
    }
  }

  if (values.password) {
    if (values.password.length > 20) {
      errors.password = 'La contraseña no puede ser mayor a 20 caracteres';
    }
  }

  return errors;
}

const SingInComponent = reduxForm({
  form: 'SignIn',
  validate
})(SignIn);

export default withRouter(SingInComponent)
