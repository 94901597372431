const config = {
  gateway: process.env.REACT_APP_API_BASE_URL || "GATEWAY_AX",
  vault: process.env.REACT_APP_API_DOCUMENT_URL || "GATEWAY_AX",
  basicAuth: {
    username: process.env.REACT_APP_BASIC_AUTH_USERNAME || "BASIC_AUTH_USERNAME_AX",
    password: process.env.REACT_APP_BASIC_AUTH_PASSWORD || "BASIC_AUTH_PASSWORD_AX",
  },
  basicToken: process.env.REACT_APP_TOKEN_BASIC_AUTH || "Z2VzdGlvbjpHZTV0MTBuITIwMjA="
};

export default config;